import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image/withIEPolyfill"
import styled from "styled-components"

const MainLogo = styled(Image)`
  flex: 1;
  width: 100%;
  max-width: 500px;
`

const Logo: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 1) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <MainLogo
      fluid={data.logo.childImageSharp.fluid}
      objectFit="contain"
      alt="l’attrape miel - miel de la montagne limousine"
    />
  )
}

export default Logo

import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Image from "gatsby-image/withIEPolyfill"
import jump from "jump.js"

import Layout from "src/layout"
import Column from "src/layout/column"
import RichText from "src/components/RichText"
import Logo from "src/components/Logo"
import Menu from "src/components/Menu"
import ResponsiveImageSwitcher from "src/components/ResponsiveImage"
import NewsletterForm from "src/components/NewsletterForm"
import { COLORS, SIZES } from "src/helpers/constants"
import ExpandIcon from "src/svg/expand.svg"

const Slide = styled.div`
  height: 100vh;
  position: relative;
`
const Photo = styled(Image)`
  height: 100%;
`
const Bottom = styled(RichText)`
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${COLORS.transparentYellow};
  font-size: ${SIZES.menu}px;
  p {
    margin: 0;
  }
  @media (min-width: 500px) {
    padding-right: 140px;
  }
`
const Splash = styled(Slide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 30px 0;
`

interface DataType {
  home: { nodes: HomePhoto[] }
  logo: { childImageSharp: Media }
}

const HomePage: GatsbyPage<DataType> = ({ data }) => {
  const photos = data.home.nodes
  photos.sort((left, right) => (left.position > right.position ? 1 : -1))

  const handleJump = (nextIndex: number) => () => {
    const element = document.getElementById(`slide-${photos[nextIndex].id}`)
    jump(element, {
      duration: 500,
      // prioritize seeing the bottom text if viewport height is smaller than 100vh:
      offset: element.scrollHeight - window.innerHeight,
    })
  }

  const ref = (el: HTMLDivElement) => {
    if (el) {
      // fix for mobile devices
      // inspired by https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      el.style.height = `${window.innerHeight}px`
    }
  }

  return (
    <Layout home>
      <Menu />
      <Splash ref={ref}>
        <Logo />
        <button onClick={handleJump(0)} aria-hidden>
          <ExpandIcon />
        </button>
      </Splash>
      {photos.map((photo) => (
        <Slide key={photo.id} id={`slide-${photo.id}`}>
          <ResponsiveImageSwitcher>
            <Photo className="mobile" fluid={photo.mobilePhoto.fluid} alt={photo.title} />
            <Photo className="desktop" fluid={photo.desktopPhoto.fluid} alt={photo.title} />
          </ResponsiveImageSwitcher>
          {photo.description && <Bottom node={photo.description} />}
        </Slide>
      ))}
      <Column>
        <NewsletterForm />
      </Column>
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query {
    home: allContentfulHomePhoto {
      nodes {
        id: contentful_id
        position
        title
        description {
          json
        }
        mobilePhoto {
          fluid(maxHeight: 800, quality: 65) {
            ...GatsbyContentfulFluid
          }
        }
        desktopPhoto {
          fluid(maxWidth: 2000, quality: 65) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

import styled from "styled-components"

const ResponsiveImageSwitcher = styled.div`
  height: 100%;
  @media (orientation: portrait) {
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
    }
  }
  @media (orientation: landscape) {
    .desktop {
      display: flex;
    }
    .mobile {
      display: none;
    }
  }
`

export default ResponsiveImageSwitcher
